// import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import './plugins/element.js'

//导入axios发起请求
// import axios from 'axios'
//封装axios，用来对不需要认证的请求进行设置
const basicServer = "https://tsyun.top/train_api/"
const publicApi = axios.create({
  baseURL: basicServer + "public/"
})
publicApi.interceptors.request.use(config => {
  //最后必须return config
  return config
})
Vue.prototype.$publicHttp = publicApi

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
