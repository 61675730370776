<template>
  <div class="home">
    <!-- 网页头部 -->
    <div class="header">
      <div class="header_box">
        <div class="logo_area">
          <img src="~@/assets/logo256x256.png" alt="">
          <span class="company_name">天上云软件</span>
        </div>
        <div class="nav_area">
          <div :class="index===activeMenuIndex?'active_nav one_nav':'one_nav'" 
            v-for="(item,index) in navs" :key="index"
            @click="scrollToPoint(item.navId)">
            {{item.navName}}
          </div>
          <div class="quick_menu">
            <el-dropdown>
              <span class="el-dropdown-link">
                <img src="~@/assets/booms.png" alt="">
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="toDownload">习练通App下载</el-dropdown-item>
                <el-dropdown-item @click.native="toPcWeb">习练通网页版</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <!-- 公司简介部分 -->
      <a name="#about" id="about" class="anmao"></a>
      <div class="company_about">
       <h1 class="title">公司简介</h1>
        <div class="about_info">
          <div class="about_img">
            <el-carousel trigger="click">
              <el-carousel-item v-for="(item,index) in aboutSwipers" :key="index">
                <img :src="item.imgSrc" alt="">
                <div class="slogan">
                  {{item.slogan}}
                </div>
              </el-carousel-item>
            </el-carousel>            
          </div>
          <div class="about_txt">
            <p>天上云软件，全称苏州天上云软件科技有限公司，于2021年3月底成立于苏州市。</p><br/>
            <p>公司主营业务为软件开发。包括但不限于网站开发，移动端app开发，小程序开发，微信公众号开发，电脑端桌面应用开发。</p><br/>
            <p>公司主打简洁实用的软件风格，力求终端用户感受到使用软件带来的便利与高效。</p><br/>
            <p>公司将客户，终端用户和公司的双赢和多赢作为发展目标和企业文化。</p><br/>
            <p>公司为初创企业，乐意与个人，小微企业，中大型企业开展软件开发和项目合作，合作方式等你解锁~</p><br/>
          </div>
        </div>
      </div>
      <!-- 项目开发部分 -->
      <a name="#develop" id="develop" class="anmao"></a>
      <div class="project_develop">
        <h1 class="title">项目开发</h1>
        <div class="project_info">          
          <div class="project_txt">
            <p>公司按照一定规范进行软件项目开发，能够在项目交付时提供完整的文档，源码，报告。</p><br/>
            <p>支持迭代式开发，应对项目初期无法预料到的需求改动以及由于业务调整对应的需求变更。</p><br/>
            <p>项目开发的基本方式：</p><br/>
            <p>1、软件定制开发，长期维护，支付开发和维护费用</p><br/>
            <p>2、软件定制或部分定制开发，长期租用，支付租用费用</p><br/>
            <p>3、软件协作开发，按协商比例支付费用</p><br/>
            <p>4、软件项目合作，按软件项目本身收益提成</p><br/>
            <p>5、更多合作方式，由双方协商拟定</p><br/>
          </div>
          <div class="project_program">
            <div class="background"></div>
            <div class="analyse">需求分析</div>
            <div class="model">原型图设计</div>
            <div class="ui">UI设计</div>
            <div class="api">API设计</div>
            <div class="database">数据库设计</div>
            <div class="front">前端实现</div>
            <div class="back">后端实现</div>
            <div class="test">测试与部署</div>
          </div>
        </div>
      </div>
      <!-- 习练通部分 -->
      <a name="#train" id="train" class="anmao"></a>
      <div class="train_area">
        <h1 class="title">习练通</h1>
        <div class="train_txt">习练通是公司开发的一款用于辅助学习与训练的软件，目前包括移动端和网页端。</div> 
        <div class="train_info">
          <div class="train_links">
            <div class="web_use">
              <h4>电脑端网页版</h4>
              <div class="details">
                <div class="div_img pc_web" @click="toPcWeb">
                  <img src="/download_imgs/pc_use.png" alt="电脑端网页版">
                </div>
                <div class="tips">单击上图，或者访问网址 https://tsyun.top/train</div>
              </div>
            </div>
            <div class="android_download">
              <h4>移动端app下载(仅安卓)</h4>
              <div class="details">                
                <div class="div_img"><img src="/download_imgs/aliapp-spread.png" alt="安卓app下载二维码"></div>
                <div class="tips">直接手机扫码下载，或者通过 <a href="https://server.m.pp.cn/download/apk?appId=8315803">点击下载</a> 进行下载</div>
              </div>
            </div>            
          </div>
          <div class="iphone_use">
            <h4>iphone版使用说明</h4>
            <div class="details">
              <div class="one_step">
                <p class="note">第一步，用iphone的浏览器扫描如下二维码，或者访问网址 https://tsyun.top/h5_train </p>
                <div class="img_info">
                  <img src="/download_imgs/h5train-spread.png" alt="https://tsyun.top/h5_train">
                </div>
              </div>
              <div class="one_step"> 
                <p class="note">第二步，扫码或访问后进入浏览器网站主页如下，这里以iphone自带的Safari浏览器为例，点击图示按钮 </p>
                <div class="img_info">
                  <img src="/download_imgs/h5index.jpg" alt="移动端主页">
                </div>
              </div>
              <div class="one_step"> 
                <p class="note">第三步，点击“添加到主屏幕”按钮 </p>
                <div class="img_info">
                  <img src="/download_imgs/toMainScreen.jpg" alt="添加到主屏幕">
                </div>
              </div>
              <div class="one_step"> 
                <p class="note">第四步，可以对标题重命名，之后点击“添加”按钮 </p>
                <div class="img_info">
                  <img src="/download_imgs/saveEdit.png" alt="确定添加">
                </div>
              </div>
              <div class="one_step"> 
                <p class="note">将网站添加到主屏幕效果如下，可以方便后序访问 </p>
                <div class="img_info">
                  <img src="/download_imgs/result.jpg" alt="添加后的效果">
                </div>
              </div>
            </div>
          </div>
        </div>          
        <!-- <div class="train_txt">下图为习练通移动端和网页端的部分截图。软件更多信息可下载app或访问网页版查看。</div>           
        <div class="train_img">
          <el-carousel height="650px" :interval="4000" trigger="click" @change="trainImgChange">
            <el-carousel-item :class="index===trainImgIndex?'now_train_img':'other_train_img'" v-for="(item,index) in trainSwipers" :key="index">
              <el-image :src="item.imgSrc" alt=""></el-image>
            </el-carousel-item>
          </el-carousel>
        </div>            -->
      </div>
      <!-- 联系我们部分 -->
      <a name="#contact" id="contact" class="anmao"></a>
      <div class="contact_area">
        <h1 class="title">联系我们</h1>
        <div class="contact_info">
          <div class="contact_txt">
            <p>地址：苏州市姑苏区新庄西路12号</p><br/>
            <p>电话：13396097230（李）</p><br/>
            <p>邮箱：1401717460@qq.com</p><br/>
          </div>       
        </div>
      </div>
    </div>
    <!-- 网页尾部 -->
    <div class="footer">
      <div class="record">Copyright © 苏州天上云软件科技有限公司 | <el-link :underline="false" href="https://beian.miit.gov.cn" target="_blank">苏ICP备2021047590号</el-link>, All Rights Reserved</div>
      <!-- <div class="policy">粤公网安备 44030702003035号</div> -->
      <div style="width:300px;margin:0 auto;">
		 		<a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32050802011466" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img src="~@/assets/beian.png" style="float:left;"/><p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">苏公网安备 32050802011466号</p></a>
		 	</div>		 
    </div>
    <!-- app下载对话框 -->
    <!-- <el-dialog
      title="app下载"
      :visible.sync="appDialogVisible"
      width="30%">
      <div class="dialog_row">* 使用app建议直接在移动端应用商店进行搜索下载</div>
      <div class="dialog_row">移动端app暂未完全发行，近期会更新，请先使用移动端网页版</div>
      <div class="dialog_row">移动端网址: https://tsyun.top/h5_train</div>
    </el-dialog> -->
    <!-- 提示切换成移动网页版的对话框 -->
    <el-dialog
  title="提示"
  :visible.sync="mobileDialogVisible"
  width="80%"
  :before-close="handleClose">
  <span>检测到您当前位于移动端，建议切换为移动网页版</span>
  <span slot="footer" class="dialog-footer">
    <el-button @click="mobileDialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="goToWap">确 定</el-button>
  </span>
</el-dialog>
  </div>
</template>

<script>

export default {
  name: 'Home',
  data() {
    return {
      scrollBox: null,
      activeMenuIndex: 0,
      navs: [
        {navId: "#about", navName:"公司简介"},
        {navId: "#develop", navName:"项目开发"},
        {navId: "#train", navName:"习练通"},
        {navId: "#contact", navName:"联系我们"},
      ],
      aboutSwipers: [
        {imgSrc: "/about_swipers/swiper1.png", slogan:"简洁实用的软件风格，带来的便利与高效"},
        {imgSrc: "/about_swipers/swiper2.png", slogan:"双赢和多赢作为发展目标和企业文化"},
        {imgSrc: "/about_swipers/swiper3.png", slogan:"与个人，小微企业，中大型企业开展软件开发和项目合作"}
      ],
      trainSwipers: [
        {imgSrc: "/example_swipers/swiper1.png"},
        {imgSrc: "/example_swipers/swiper2.png"},
        {imgSrc: "/example_swipers/swiper3.png"},
        {imgSrc: "/example_swipers/swiper4.png"}
      ],
      trainImgIndex: 0,
      // appDialogVisible: false,
      mobileDialogVisible: false
    }
  },
  created(){
    //检查当前是否在移动端进行访问
    // 如果在移动端，就提示用户切换到移动网页端
    if (navigator.userAgent.indexOf('Mobile') > -1) {  
      //this.mobileDialogVisible = true
      //注释上面代码后，不提示用户切换，直接切换
      this.goToWap()
    }  
  },
  mounted(){
    window.setTimeout(()=>{},500)
    const jumps = document.querySelectorAll(".anmao")    
    const topArr = []
    for(let i=0;i<jumps.length;i++){
      topArr.push(jumps[i].offsetTop)
    }
    console.log(topArr);
    window.addEventListener("scroll",()=>{
      let current_top = document.documentElement.scrollTop
      for(let i=0;i<topArr.length;i++){
        if(i===0){
          if(current_top <= topArr[i]){
            this.activeMenuIndex = i
            break
          }
        }else{
          current_top += 50
          if(current_top > topArr[i-1] && current_top <= topArr[i]){
            this.activeMenuIndex = i
            break
          }
        }        
      }
    },true)
  },
  methods: {
    scrollToPoint(elementId){
      document.querySelector(elementId).scrollIntoView(true)
    },
    //习练通走马灯位置改变
    trainImgChange(nowIndex,srcIndex){
      this.trainImgIndex = nowIndex
    },
    //打开电脑网页版的习练通
    toPcWeb(){
      window.open("https://www.tsyun.top/train","_blank")
    },
    //切换到移动端网页版
    goToWap(){
      location.href = "https://tsyun.top/h5_tsyun"
    },
    //定位到下载软件的地方
    toDownload(){
      document.querySelector("#train").scrollIntoView(true)
    }
  }
}
</script>

<style lang="less" scoped>
@main_color: #3366CC;

.home{
  overflow-x: hidden;
  position: relative;  
}

.header{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  height: 100px;
  width: 100%;
  background-color: #F2F2F2;
  box-shadow: 0 3px 10px #888888;
  .header_box{
    min-width: 1024px;
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    .logo_area{
      display: flex;
      align-items: center;
      img{
        width: 80px;
        height: 80px;
        padding: 10px;
      }
      .company_name{
        font-size: 28px;
        color: @main_color;
      }
    }

    .nav_area{
      display: flex;
      align-items: center;
      a{
        text-decoration: none;
        outline: none;
      }
      .one_nav{
        padding: 15px;
        margin: 0 5px;
        font-size: 18px;
        font-weight: bold;
        color: #333333;        
      }
      img{
        padding: 15px 25px;
        width: 20px;
        height: 20px;
      }
      .active_nav,.one_nav:hover{
        border-bottom: 3px solid @main_color;        
        cursor: pointer;
        color: @main_color;
      }

      .quick_menu{        
        cursor: pointer;
        .el-dropdown-item{
          padding: 0;
          div{
            width: 100%;
            height: 100%;
          }
        }
      }      
    }
  }  
}

.anmao{
  height: 0;
  position: relative;
  top: -100px;
}

.content{
  min-width: 1024px;
  max-width: 1400px;
  padding: 20px;
  margin: 0 auto;
  padding-top: 100px;
  .title{
    margin: 20px 0;
  }
  .company_about{    
    .about_info{
      display: flex;
      .about_img{
        margin-right: 20px;
        min-width: 550px;
        max-width: 550px;
        position: relative;
        img{
          width: 100%;
          height: 100%;
        } 
        .slogan{
          position: absolute;  
          left: 0;
          bottom: 0;
          height: 60px;
          width: 100%;
          line-height: 45px;
          text-align: center;
          background-color: rgba(0,0,0,0.35);
          color: #FFFFFF;
          z-index: 5;
        }
      }
    }
  }
  .project_develop{    
    .title{
      text-align: right;
    }
    .project_info{
      display: flex;
      .project_program{        
        min-width: 600px;  
        max-width: 600px;
        min-height: 336px;   
        max-height: 336px;     
        margin: 0 auto;
        position: relative;
        .background{
          width: 450px;
          height: 200px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          border: 10px solid @main_color;
          border-radius: 50%;
          z-index: 1;          
        }
        .analyse,.model,.ui,.api,.database,.front,.back,.test{
          width: 100px;
          height: 100px;
          text-align: center;
          line-height: 100px;
          color: #FFFFFF;
          position: absolute;
          border-radius: 50%;
          z-index: 2;
        }
        .analyse{          
          top: 120px;
          left: 25px;
          background-color: #EC808D;
        }
        .model{
          top: 35px;
          left: 110px;
          background-color: #F59A23;
        }
        .ui{
          top: 15px;
          left: 250px;
          background-color: #BFBF00;
        }
        .api{
          top: 35px;
          right: 110px;
          background-color: #00BFBF;
        }
        .database{
          top: 120px;
          right: 25px;
          background-color: #027DB4;
        }
        .front{
          top: 200px;
          right: 110px;
          background-color: #0000BF;
        }
        .back{
          bottom: 15px;
          left: 250px;
          background-color: #6300BF;
        }
        .test{
          top: 200px;
          left: 110px;
          background-color: @main_color;
        }
      }
    }
  }

  .train_area{
    .train_txt{
      margin: 20px 0;
    }
    .train_links{
      display: flex;
      margin-bottom: 20px;
      .web_use,.android_download{
        display: flex;
        flex-direction: column;
        width: 300px;
        margin-right: 30px;
        h4{
          padding: 10px 0;
        }
        .details{
          margin: 20px 0;
          .div_img{
            height: 300px;
            display: flex;
            align-items: center;
            background: rgba(0,0,0,0.1);
            img{
              width: 100%;
              vertical-align: middle;
            }
          }
          .tips{
            margin-top: 20px;
            font-size: 14px;
            color: #666;
          }
          .pc_web{
            cursor: pointer;
          }
          .pc_web:hover{
            background-color: rgba(0,0,255,0.2);
          }
        }        
      }
    }
  }

  .iphone_use{
    .details{
      display: flex;
      .one_step{
        width: 300px;
        margin-top: 20px;
        margin-right: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .note{
          font-weight: bold;
          color: #666;
          font-size: 14px;
          margin-bottom: 15px;
        }
        .img_info{
          height: 300px;
          display: flex;
          align-items: center;
          background: rgba(0,0,0,0.1);
          img{
            height: 100%;
            margin: 0 auto;
          }
        }     
      }
    }
  }

  .contact_area{    
    .title{
      text-align: right;
    }
    .contact_txt{
      font-size: 14px;
    }
  }
}

.footer{
  height: 100px;
  background-color: #D7D7D7;
  box-shadow: 0 -3px 10px #888888;
  font-size: 14px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  .number{
    color: @main_color;
    cursor: pointer;
  }
}

.el-dialog{
  .dialog_row{
    padding: 10px 0;
    .el-link{
      margin-right: 40px;
      color: @main_color;
    }
  }
}

</style>